(function() {
  'use strict';

  /*!
   * IE10 viewport hack for Surface/desktop Windows 8 bug
   */
  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
      document.createTextNode(
        '@-ms-viewport{width:auto!important}'
      )
    )
    document.head.appendChild(msViewportStyle)
  }

  // Skylarq JS
  $('[data-toggle]').on('click', function(e) {
    e.preventDefault();
    var $parent = $(this).closest('.panel');
    if ($parent.hasClass('active')) {
      $parent.removeClass('active');
    }
    else {
      $('.panel').removeClass('active');
      $parent.addClass('active');
    }
    
  });

})();
